import Axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { createDecipheriv } from 'crypto'
import { spacing as spacingValue } from 'constants/css'
import { ICalcSimulate, IEntity, IFactor, IReceivable } from 'interfaces'
import breakpoints from 'constants/breakpoints'
import IAddress from 'interfaces/address'
import IInsurer, { InsurerType } from 'interfaces/insurer'
import {
  cnpjNormalizer,
  cnpjValidator,
  cpfNormalizer,
  cpfValidator,
  maxLength,
  phoneNormalizer,
} from 'components/Forms'
import { regexCellPhoneCompany, regexCellPhoneUser } from 'constants/regex'
import { banks } from 'constants/general'

export const capitalize = (value: String | string | undefined): string => {
  if (!value) {
    return ''
  }
  return String(value)?.charAt(0)?.toUpperCase() + String(value)?.slice(1)
}

export const spacing = (size: number): string => {
  return `${size * spacingValue}px`
}

export const breakpoint = (size: 'sm' | 'md' | 'lg'): string => {
  return `${breakpoints[size]}px`
}

export const getFirstLetters = (word: string): String => {
  return (word || '')?.charAt(0)?.toUpperCase()
}

export const formatCurrency = (
  value: Number,
  options?: { style?: 'decimal' | 'currency' }
): string => {
  if (value === undefined) {
    return Number(0).toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: options?.style || 'currency',
    })
  }

  return Number(value).toLocaleString('pt-BR', {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: options?.style || 'currency',
  })
}

export const parsePercent = (value: string) => {
  // Substitui todos os pontos por vírgula, padrão BR.
  let formattedValue = value.replace('.', ',')
  // Remove todos os caracteres que não são números ou vírgula
  formattedValue = formattedValue.replace(/[^0-9,]/g, '')

  // Verifica se há mais de um ponto e remove os extras
  const parts = formattedValue.split(',')
  if (parts.length > 2) {
    formattedValue = `${parts[0]}.${parts[1]}`
  }

  // Limita a parte decimal a no máximo duas casas
  if (formattedValue.includes(',')) {
    const [integerPart, decimalPart] = formattedValue.split(',')
    formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`
  }

  return formattedValue
}

export const getAgeByBirthDate = (date?: string) => {
  if (!date) return 0
  return Math.abs(moment(date).diff(moment(), 'years'))
}

export const formatDate = (date?: string, format?: string, convertFormat?: string): string => {
  if (!!date) {
    const momentDate = moment(date, convertFormat)
    return momentDate.format(format || 'DD/MM/YYYY')
  }
  return '-'
}

export const formatPercent = (value: Number | String, abs: boolean = true): string => {
  if (abs && value) {
    return (Number(value) / 100).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'percent',
    })
  }
  if (value) {
    return Number(value).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'percent',
    })
  }
  return '0,00%'
}

export const parseCurrency = (value = 0) => {
  if (typeof value === 'number') {
    return value.toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    })
  }
  if (typeof value === 'string' && !isNaN(parseFloat(value))) {
    return parseFloat(value).toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    })
  }

  return 'R$ 0,00'
}

export const formatNumberToTwoDecimals = (value: number): number => {
  if (typeof value === 'string') {
    return parseFloat(Number(value).toFixed(2))
  }
  return parseFloat(value.toFixed(2))
}

export const formatPhoneNumber = (str?: string) => {
  if (str) {
    const cleaned = str.replace(/(\+\d{2})/g, '')

    if (!cleaned) {
      return ''
    }

    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }

    if (!match && cleaned.length < 11) {
      return cleaned
    }
  }

  return 'Não informado'
}

export const removeEmptyKeys = (obj: any): Object => {
  return Object.keys(obj).reduce((result, key) => {
    const objValue = obj[key]
    if (!objValue) {
      return result
    }
    if (objValue instanceof Object) {
      const keyValues = removeEmptyKeys(objValue)
      return {
        ...result,
        [key]: keyValues,
      }
    }
    return {
      ...result,
      [key]: objValue,
    }
  }, {})
}

export const getPasswordScore = (passwordScore: number) => {
  const scores = [
    [0, 'secondary', 'Senha inválida'],
    [25, 'secondary', 'Senha não permitida'],
    [50, 'primary', 'Senha média'],
    [75, 'primary', 'Senha boa'],
    [100, 'primary', 'Senha ótima'],
  ]
  return scores[passwordScore]
}

export const getCepInfos = (cep: string, change: (name: string, value?: any) => void) => {
  if (typeof cep === 'string' && cep.length === 9) {
    Axios({
      url: `${process.env.REACT_APP_CREDITOR_BASE_URL}/v3/correios/cep/consultar/${cep.replace(
        /[^0-9]/,
        ''
      )}`,
      method: 'GET',
    }).then((address: AxiosResponse<IAddress>) => {
      const { logradouro, complemento, bairro, localidade, uf } = address.data
      change('endereco.cidade', localidade)
      change('endereco.bairro', bairro)
      change('endereco.complemento', complemento)
      change('endereco.uf', uf)
      change('endereco.logradouro', logradouro)
    })
  }
}

export const formatErrors = (errors: Record<string, unknown>): string | undefined => {
  if (!errors) return ''

  const extractMessage = (errorObj: unknown): string | undefined => {
    if (typeof errorObj === 'string') {
      return errorObj
    }
    if (Array.isArray(errorObj) && typeof errorObj[0] === 'string') {
      return errorObj[0]
    }
    if (typeof errorObj === 'object' && errorObj !== null) {
      for (const key in errorObj as Record<string, unknown>) {
        const message = extractMessage((errorObj as Record<string, unknown>)[key])
        if (message) return message
      }
    }
    return undefined
  }

  return extractMessage(errors)
}

export const roundCurrencyValue = (num: number) => {
  return Math.round(num * 100) / 100
}

export const truncCurrencyValue = (num: number) => {
  return Math.floor(num * 100) / 100
}

export const verifyLimits = (value: number, max: number, min: number) => {
  const maxValue = truncCurrencyValue(max)
  const minValue = truncCurrencyValue(min)

  if (value >= maxValue) {
    return maxValue
  }

  if (value <= minValue) {
    return minValue
  }

  return value
}

export const calcSimulateInsurer = (
  value: number,
  factor: IFactor,
  isParcel?: boolean,
  insurer?: IInsurer
) => {
  if (!insurer) {
    return 0
  }

  let taxValue = Math.min(factor.tarifas, factor.valor_maximo_tarifa)

  if (!isParcel) {
    if (factor.tarifa_porcentagem) {
      taxValue = (value * factor.tarifas) / 100
    }
    taxValue = Math.min(taxValue, factor.valor_maximo_tarifa)

    if (insurer.tipo_seguro === InsurerType.FINANCIADO) {
      if (factor.seguro_porcentagem) {
        const calculatedInsurer = value * (factor.seguro / 100)
        const calculatedInstallmentWithInsurer = value + calculatedInsurer + taxValue
        return calculatedInstallmentWithInsurer / factor.fator
      }
      return (value + factor.seguro + taxValue) / factor.fator
    }

    if (insurer.tipo_seguro === InsurerType.PARCELA) {
      if (factor.seguro_porcentagem) {
        const calculatedParcel = (value + taxValue) / factor.fator
        return calculatedParcel * (1 + factor.seguro / 100)
      }

      const calculatedParcel = (value + taxValue) / factor.fator
      return calculatedParcel + factor.seguro
    }
  }

  if (isParcel) {
    const newValue = value * factor.fator

    if (insurer.tipo_seguro === InsurerType.PARCELA) {
      if (factor.seguro_porcentagem) return value + value * (factor.seguro / 100)

      return value + factor.seguro
    }

    if (insurer.tipo_seguro === InsurerType.FINANCIADO) {
      if (factor.tarifa_porcentagem && factor.seguro_porcentagem)
        return Math.max(
          newValue / (1 + (factor.seguro + factor.tarifas) / 100),
          (newValue - factor.valor_maximo_tarifa) / (1 + factor.seguro / 100)
        )
      else if (factor.tarifa_porcentagem) {
        const valueWithInsurer = newValue - factor.seguro
        return Math.max(
          valueWithInsurer / (1 + factor.tarifas / 100),
          valueWithInsurer - factor.valor_maximo_tarifa
        )
      } else if (factor.seguro_porcentagem) {
        return (newValue - taxValue) / (1 + factor.seguro / 100)
      }

      return newValue - taxValue - factor.seguro
    }
  }

  return 0
}

export const calcSimulate = ({
  values,
  factor,
  isParcel,
  insurer,
  isSimularPost,
  enabledSecurity,
  isCorban = false,
}: ICalcSimulate) => {
  let newInsurerValue = 0
  if (enabledSecurity) newInsurerValue = calcSimulateInsurer(values, factor, isParcel, insurer)
  const isInsurerFinancial = insurer?.tipo_seguro === InsurerType.FINANCIADO
  const isTaxeInPercent = factor.tarifa_porcentagem

  if (isSimularPost && !isCorban) {
    if (isInsurerFinancial) {
      const newValueWithoutInsurer = factor.recebivel - factor.seguro / factor.prazo
      return {
        newValue: roundCurrencyValue(isParcel ? factor.valor_liberado : newValueWithoutInsurer), // Formula para valor solicitado/parcela de seguro do tipo financiado
        newInsurerValue: isParcel ? 0 : factor.recebivel,
      }
    }
    return {
      newValue: roundCurrencyValue(isParcel ? factor.valor_liberado : factor.recebivel), // Formula para valor solicitado/parcela de seguro do tipo parcela
      newInsurerValue: factor.recebivel + factor.seguro,
    }
  }

  let taxValue = Math.min(factor.tarifas, factor.valor_maximo_tarifa)
  if (isParcel) {
    let newValue = roundCurrencyValue(values * factor.fator)

    if (isTaxeInPercent) {
      newValue = Math.max(
        newValue / (1 + factor.tarifas / 100),
        newValue - factor.valor_maximo_tarifa
      )
    } else newValue -= taxValue

    return {
      newValue,
      newInsurerValue,
    }
  }

  // Calculos para valor solicitado
  if (!isParcel) {
    if (isTaxeInPercent) {
      const percentTax = factor.tarifas / 100
      taxValue = Math.min(values * percentTax, factor.valor_maximo_tarifa)
      return {
        newValue: roundCurrencyValue(values + taxValue) / factor.fator, // Formula 1
        newInsurerValue,
      }
    }
  }

  return {
    newValue: roundCurrencyValue((values + taxValue) / factor.fator), // Formula 1
    newInsurerValue,
  }
}

export const isModality = (entity: IEntity) => {
  if (entity) {
    if (entity.module?.subModule === 'CP') return 'CP'
    if (entity.module?.subModule === 'CDC') return 'CDC'
    if (entity.module?.subModule === 'CDCVEICULO') return 'CDCVEICULO'
    if (entity.module?.subModule === 'PJ') return 'PJ'
    if (entity.module?.subModule === 'SUPERVISOR') return 'SUPERVISOR'
    if (entity.id === 'corban') return 'CORBAN'
  }
  return 'CEP'
}

export const setItem = (key: string, value: string): void => {
  localStorage.setItem(key, value)
}

export const getItem = (key: string): string => {
  return localStorage.getItem(key) || ''
}

export const removeItem = (key: string): void => {
  localStorage.removeItem(key)
}

export const formatCurrencyNumber = (value: number) => {
  return (value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  })
}
export const showInformationsAboutLot = (item: IReceivable, dontPay?: boolean) => {
  if (
    item.lote &&
    item.lote.status !== 'conciliado' &&
    item.lote.status !== 'rascunho' &&
    item.lote.status !== 'conciliado-automaticamente' &&
    item.pagamentos?.length === 0 &&
    dontPay
  ) {
    return true
  }
  return false
}

export const typeNormalizer = (value: string) => {
  if (value === 'cpf') return cpfNormalizer
  if (value === 'cnpj') return cnpjNormalizer
  if (value === 'telefone_celular') return phoneNormalizer
  if (value === 'email') return maxLength(50)
  return maxLength(32)
}

export const typeValidate = (value: string) => {
  if (value === 'cpf') return cpfValidator
  if (value === 'cnpj') return cnpjValidator
}

export const removeDuplicated = (array: any[]) => {
  const ids = new Set()
  const result = []

  for (const item of array) {
    if (!ids.has(item.id)) {
      result.push(item)
      ids.add(item.id)
    }
  }

  return result
}

export const removeDuplicatedCompanies = (array: any[]) => {
  const ids = new Set()
  const result = []

  for (const item of array) {
    if (!ids.has(item.entidade.id)) {
      result.push(item)
      ids.add(item.entidade.id)
    }
  }

  return result
}

export const testAfterNormalization = (value: string, isCompany: boolean) => {
  if (isCompany) return regexCellPhoneCompany.test(phoneNormalizer(value))
  return regexCellPhoneUser.test(phoneNormalizer(value))
}

function aesDecrypt(ciphertext: Buffer, key: Buffer, iv: Buffer) {
  const decifrar = createDecipheriv('aes-128-cbc', key, iv)
  const decifrado = Buffer.concat([decifrar.update(ciphertext), decifrar.final()])

  return decifrado.toString('utf-8')
}

export function decodeAndDecrypt(dadoCodificado: string) {
  const key = Buffer.from('d3C1fr4B4ix0n3NG', 'utf-8') // TODO: Mover para variável de ambiente
  const iv = Buffer.from('j0mb4SV3t0res123', 'utf-8') // TODO: Mover para variável de ambiente
  const decodedData = Buffer.from(dadoCodificado, 'base64')
  const decryptedData = aesDecrypt(decodedData, key, iv)
  return decryptedData.substring(0, 5)
}

export const filteredBanksByEnv = () => {
  const isSer = ['serfinancas', 'serfinance'].some((keyword) =>
    process.env.REACT_APP_CREDITOR_BASE_URL?.includes(keyword)
  )
  if (!isSer) return banks
  const filteredBanks = banks.filter((bank) => bank.value === '530' || bank.value === '566')
  return filteredBanks
}
