import { HomeOutlined } from '@material-ui/icons'
import LazyLoading from 'components/LazyLoading'
import { routeCodes, subRouteCodes } from 'constants/routes'

const Default = LazyLoading(() => import('./pages/Default'))
const Dashboard = LazyLoading(() => import('./pages/Dashboard'))
const Location = LazyLoading(() => import('./pages/Location'))
const MyAccount = LazyLoading(() => import('app/Default/pages/MyAccount'))
const MyAccountList = LazyLoading(() => import('app/Default/pages/MyAccount/List'))
const MyAccountChangeMail = LazyLoading(() => import('app/Default/pages/MyAccount/ChangeMail'))
const MyAccountChangePassword = LazyLoading(() =>
  import('app/Default/pages/MyAccount/ChangePassword')
)
const MyAccountChangePhone = LazyLoading(() => import('app/Default/pages/MyAccount/ChangePhone'))
const MyAccountToken = LazyLoading(() => import('app/Default/pages/MyAccount/Token'))

export const SelfAttendanceRoutes = {
  DEFAULT: {
    path: routeCodes.NULL_ROUTE,
    name: 'Página inicial',
    component: Default,
    hideInSidePanel: true,
  },
  DASHBOARD: {
    path: routeCodes.SELF_ATTENDANCE.DASHBOARD,
    name: 'Página Inicial',
    component: Dashboard,
    icon: HomeOutlined,
    defaultRoute: true,
  },
  LOCATION: {
    path: routeCodes.SELF_ATTENDANCE.LOCATION,
    name: 'Habilitar localização',
    component: Location,
    hideInSidePanel: true,
  },
  MY_ACCOUNT: {
    path: routeCodes.SELF_ATTENDANCE.MY_ACCOUNT,
    name: 'Minha Conta',
    component: MyAccount,
    hideInSidePanel: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Minha Conta',
        component: MyAccountList,
      },
      CHANGE_MAIL: {
        path: subRouteCodes.SELF_ATTENDANCE.MY_ACCOUNT.CHANGE_MAIL,
        name: 'Alterar email',
        component: MyAccountChangeMail,
      },
      CHANGE_PASSWORD: {
        path: subRouteCodes.SELF_ATTENDANCE.MY_ACCOUNT.CHANGE_PASSWORD,
        name: 'Alterar Senha',
        component: MyAccountChangePassword,
      },
      CHANGE_TELEPHONE: {
        path: subRouteCodes.SELF_ATTENDANCE.MY_ACCOUNT.CHANGE_PHONE,
        name: 'Alterar Telefone',
        component: MyAccountChangePhone,
      },
      TOKEN: {
        path: subRouteCodes.SELF_ATTENDANCE.MY_ACCOUNT.TOKEN,
        name: 'Confirmar código',
        component: MyAccountToken,
      },
    },
  },
}

export default SelfAttendanceRoutes
