import * as React from 'react'

function LogoVuon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox='0 0 1980 1020'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill='#4a4796'
        d='M1325.85,428.29v223.41c33.59-22.33,52.31-56.93,52.31-103.83v-15.74c0-46.91-18.73-81.5-52.31-103.84M1241.53,549.91c0,46.24-44,68.87-111.16,68.87s-111.16-22.63-111.16-68.87v-19.82c0-46.24,43.99-68.87,111.16-68.87s111.16,22.63,111.16,68.87v19.82ZM1130.37,397.84c-109.74,0-177.86,44.77-177.86,134.29v15.74c0,89.53,68.12,134.29,177.86,134.29s177.85-44.76,177.85-134.29v-15.74c0-89.53-68.11-134.29-177.85-134.29M1395.78,637.51c22.43-21.86,34.69-51.72,34.69-89.64v-15.74c0-37.93-12.26-67.78-34.69-89.64v195.02ZM1655.7,403.3c-55.34,0-103.59,27.91-128.66,63.38v-56.29h-70.48v260.16h70.48v-170.29c24.13-18.45,73.32-31.69,108.32-31.69,53.45,0,71.9,17.5,71.9,74.26v127.72h70.48v-141.91c0-76.63-44.46-125.35-122.04-125.35M320.59,630.82l-96.97-220.43h-81.36l120.62,260.16h115.42l120.62-260.16h-81.36l-96.97,220.43ZM877.13,532.13v15.74c0,49.38,20.76,85.12,57.77,107.26v-230.27c-37,22.15-57.77,57.88-57.77,107.26M775.62,580.68c-25.54,16.08-63.86,30.75-98.86,30.75-53.45,0-71.9-17.5-71.9-74.26v-126.77h-70.48v140.96c0,76.63,44.46,125.35,120.15,125.35,51.09,0,96.49-31.22,121.09-63.38v57.23h70.48v-260.16h-70.48v170.29Z'
      />
    </svg>
  )
}

export default LogoVuon
